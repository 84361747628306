
import React from "react";

import 'media-chrome';
import './Audio.css';

export function Audio(props) {
    const { url, width = 'auto'} = props;

    return (
        <media-controller audio class="audio-media-theme" style={{ width: width, borderRadius: '8px' }}>
            <audio
                slot="media"
                src={url}
            ></audio>
            <media-control-bar style={{display: 'flex', justifyContent:'space-between'}}>
                <media-play-button></media-play-button>
                <media-time-display showduration></media-time-display>
                <media-time-range></media-time-range>
                <media-mute-button></media-mute-button>
                {//<media-volume-range></media-volume-range>
                }
            </media-control-bar>
        </media-controller>
    );
}
