import { useTheme } from "@emotion/react";
import { AccessAlarm, Add, AllInclusive, Done, Info, LockClock, } from "@mui/icons-material";
import { Box, Button, Container, IconButton, Stack, tooltipClasses, styled, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import AudioImg from "../../assets/img/audio.png";
import { Audio } from "./player/Audio";
import { attivaBrano } from "./absAPI";
import { LoadingButton } from "@mui/lab";
import { setSnackbarData } from "../programmi/programmiSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAPIErrorMessage } from "../../common/utils";

export function CardBrano(props) {
  const { onAttivato, audio, showPrice = true, showBorder = true, showQta = false, isCardIconChecked, onCardAddClick, showPlayer = false, showCartIcon = true } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const c = theme.palette;
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [isAttivaLoading, setIsAttivaLoading] = useState(false);
  const { token, user } = useSelector((state) => state.login);

  const onAttivaBrano = async function () {
    setIsAttivaLoading(true);
    try {
      await attivaBrano(audio.id_brano_utente, token);
    } catch (err) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(err), severity: "error" }));
      return;
    } finally {
      setIsAttivaLoading(false);
    }

    onAttivato();
  };

  const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: { color: theme.palette.primary.light },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.primary.light,
      fontFamily: "Poppins-Regular",
      color: "#000",
      boxShadow: theme.shadows[1],
      fontSize: 18,
      textAlign: "center",
    },
  }));

  const getLabelDurataGiorni = function (giorni) {
    if (giorni == 1) {
      return "Limite di utilizzo di 1 giorno dall’attivazione";
    } else {
      return "Limite di utilizzo di " + audio.durata + " giorni dall’attivazione";
    }
  }

  const getLabelDurataMesi = function (preset) {
    if (preset == 'M1') {
      return "Limite di utilizzo di 1 mese dall’attivazione";
    } else if (preset == 'M3') {
      return "Limite di utilizzo di 3 mesi dall’attivazione";
    } else if (preset == 'M6') {
      return "Limite di utilizzo di 6 mesi dall’attivazione";
    } else if (preset == 'Y1') {
      return "Limite di utilizzo di 1 anno dall’attivazione";
    }
  }


  const isAudioAttivo = audio.attivazione && audio.attivazione.attivo;

  return (
    <Stack direction="column" justifyContent="center" height={matchSm ? showPlayer ? 160 : 110 : 'auto'} sx={{ borderBottom: showBorder ? '1px solid lightgrey' : '0px', mt: 1, pb: 1, border: '1px solid lightgrey', borderRadius: '12px', p: 1 }}>
      <Box sx={{ display: "flex", height: "50px", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <Box flex={1}>
          <Stack direction="row" alignItems="center" sx={{}}>
            <img src={AudioImg} alt="audio" style={{ height: '40px', width: '40px' }} />
            <Stack direction='row' sx={{ ml: 1, flex: 1 }} justifyContent='space-between'>
              <Typography variant="m" flexWrap={""}>
                {audio.nome}
              </Typography>
              {
                showPlayer && isAudioAttivo ?
                  <>
                    {
                      audio.attivazione.data_scadenza == null ?
                        <CustomTooltip title="Complimenti, Il brano è attivo! Ha una durata illimitata per l'ascolto" arrow>
                          <AllInclusive sx={{fontSize: '22px', color: c.secondary.main}}/>
                        </CustomTooltip> :
                        <CustomTooltip title={"Complimenti, Il brano è attivo! Lo puoi liberamente ascoltare sino al " + new Date(audio.attivazione.data_scadenza).toLocaleDateString()} arrow>
                          <AccessAlarm sx={{fontSize: '22px', color: audio.attivazione.in_scadenza ? 'red' : c.secondary.main }} />
                        </CustomTooltip>
                    }
                  </> :
                  <></>
              }
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "end", pl: '4px' }}>
          {
            (showPrice || (showPlayer && !isAudioAttivo)) && <>
             {
                audio.tipo_durata != "2" && audio.tipo_durata != '3' &&
                <CustomTooltip title={"Nessun limite di utilizzo del brano"} arrow>
                  <AccessAlarm sx={{ fontSize: '22px', mr: 2 }} />
                </CustomTooltip>
              }
              {
                audio.tipo_durata != null && audio.tipo_durata == '2' &&
                <CustomTooltip title={getLabelDurataGiorni(audio.durata)} arrow>
                  <AccessAlarm sx={{ fontSize: '22px', mr: 2 }} />
                </CustomTooltip>
              }
              {
                audio.tipo_durata != null && audio.tipo_durata == '3' &&
                <CustomTooltip title={getLabelDurataMesi(audio.preset_durata)} arrow>
                  <AccessAlarm sx={{ fontSize: '22px', mr: 2 }} />
                </CustomTooltip>
              }
            </>
          }

          {
            showPrice && <>
              <Box display="flex" alignItems="end" flexDirection='column'>
                {Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(audio.prezzo)}
                {//  <Typography sx={{fontSize:'10px', textAlign:'right'}}>Limite di utilizzo di 30 giorni dall’attivazione</Typography>
                }
              </Box>
            </>

          }

          {
            showQta &&
            <CustomTooltip title="Quantità disponibile per la cessione" arrow>
              <Box display="flex" alignItems="center" sx={{ border: '1px solid ' + c.primary.main, borderRadius: '30px', width: '24px', height: '24px', display: 'flex', justifyContent: 'center' }}>
                {audio.qta_disponibile}
              </Box>
            </CustomTooltip>
          }

          {
            showCartIcon ?

              <IconButton
                sx={{ backgroundColor: 'white', border: '1px solid ' + c.secondary.main, ml: 2 }}
                aria-label="Add"
                onClick={() => onCardAddClick(audio)}
              >
                {
                  isCardIconChecked(audio) ?
                    (<Done sx={{ color: c.secondary.main, fontSize: 15 }} />) :
                    (<Add sx={{ color: c.secondary.main, fontSize: 15 }} />)
                }
              </IconButton>
              : <> </>
          }
        </Box>
      </Box>
      <Typography variant="body2" overflow={"hidden"} sx={{ height: matchSm ? '60px' : 'auto', overflowY: 'auto' }}>
        {audio.descrizione}
      </Typography>
      {
        showPlayer ?
          <>
            {
              isAudioAttivo ?
                <Box>
                  <Audio url={audio.url} width='100%' />
                </Box>
                :
                <Stack flexDirection='row' alignItems="center" justifyContent="space-between" sx={{ height: '44px', display: 'flex', px: 1, py: '4px', mt: 2, backgroundColor: c.primary.main, color: 'white', borderRadius: '8px' }}>
                  {
                    user.tipologia == 'PA' ? <CustomTooltip title={"Attivando il brano per l'ascolto verrà scalata una unità disponibile per la cessione."} arrow>
                      <Info sx={{ color: 'white', mr: '4px' }} />
                    </CustomTooltip> : <></>
                  }

                  <Typography variant="body2" sx={{ alignContent: 'center', height: '44px', flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>  Attiva il brano per la riproduzione</Typography>
                  <LoadingButton loading={isAttivaLoading} onClick={onAttivaBrano} variant="contained" size="small" sx={{ color: 'white' }} color="secondary">
                    Attiva
                  </LoadingButton>
                </Stack>
            }
          </>
          : <></>
      }
    </Stack>
  );
}
