import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Backdrop,
  Paper,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  OutlinedInput,
} from "@mui/material";
import { creaAudio, modifyAudio, getAudio } from "../../dashboardAdminAPI";
import { setSnackbarData, getListaCategorieAudio, getListaAudio } from "../../dashboardAdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAPIErrorMessage } from "../../../../common/utils";
import { ArrowBack, CloudUploadOutlined, Save } from "@mui/icons-material";


const GridData = ({ data, setData, selectedFile, setSelectedFile, liste, readOnly = false } = {}) => {
  const { listaCategorieAudio } = liste;
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setData((prevData) => ({ ...prevData, [name]: type === "checkbox" ? checked : value }));
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  return (
    <>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField disabled={readOnly} label="Nome" name="nome" value={data.nome} onChange={handleInputChange} />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField disabled={readOnly} label="Codice" name="codice_media" value={data.codice_media} onChange={handleInputChange} />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel>Durata Privato</InputLabel>
          <Select
            value={data.tipo_durata_privato}
            label="Durata Privato"
            name="tipo_durata_privato"
            onChange={handleInputChange}
          >
            <MenuItem key={'1'} value={'1'}>
              Illimitata
            </MenuItem>
            <MenuItem key={'3'} value={'3'}>
              A Mesi
            </MenuItem>
            <MenuItem key={'2'} value={'2'}>
              A giorni
            </MenuItem>
          </Select>
        </FormControl>
        {
          data.tipo_durata_privato == '2' ?
            <FormControl fullWidth sx={{ mt: 2 }}>
              <TextField type="number" disabled={readOnly} label="N. Giorni" name="durata_privato" value={data.durata_privato} onChange={handleInputChange} />
            </FormControl>
            : <></>
        }
          {
          data.tipo_durata_privato == '3' ?
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Durata Privato</InputLabel>
              <Select
                value={data.preset_durata_privato}
                label="Durata Professionista"
                name="preset_durata_privato"
                onChange={handleInputChange}
              >
                <MenuItem key={'M1'} value={'M1'}>
                  1 Mese
                </MenuItem>
                <MenuItem key={'M3'} value={'M3'}>
                  3 Mesi
                </MenuItem>
                <MenuItem key={'M6'} value={'M6'}>
                  6 Mesi
                </MenuItem>
                <MenuItem key={'Y1'} value={'Y1'}>
                  1 Anno
                </MenuItem>
              </Select>
            </FormControl>
            : <></>
        }
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel>Durata Professionista</InputLabel>
          <Select
            value={data.tipo_durata_professionista}
            label="Durata Professionista"
            name="tipo_durata_professionista"
            onChange={handleInputChange}
          >
            <MenuItem key={'1'} value={'1'}>
              Illimitata
            </MenuItem>
            <MenuItem key={'3'} value={'3'}>
              A Mesi
            </MenuItem>
            <MenuItem key={'2'} value={'2'}>
              A giorni
            </MenuItem>
          </Select>
        </FormControl>
        {
          data.tipo_durata_professionista == '2' ?
            <FormControl fullWidth sx={{ mt: 2 }}>
              <TextField type="number" disabled={readOnly} label="N. Giorni" name="durata_professionista" value={data.durata_professionista} onChange={handleInputChange} />
            </FormControl>
            : <></>
        }
        {
          data.tipo_durata_professionista == '3' ?
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Durata Professionista</InputLabel>
              <Select
                value={data.preset_durata_professionista}
                label="Durata Professionista"
                name="preset_durata_professionista"
                onChange={handleInputChange}
              >
                <MenuItem key={'M1'} value={'M1'}>
                  1 Mese
                </MenuItem>
                <MenuItem key={'M3'} value={'M3'}>
                  3 Mesi
                </MenuItem>
                <MenuItem key={'M6'} value={'M6'}>
                  6 Mesi
                </MenuItem>
                <MenuItem key={'Y1'} value={'Y1'}>
                  1 Anno
                </MenuItem>
              </Select>
            </FormControl>
            : <></>
        }
      </Grid>

      <Grid item xs={4} />
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField disabled={readOnly} multiline minRows={3} maxRows={5} label="Descrizione" name="descrizione" value={data.descrizione} onChange={handleInputChange} />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField disabled={readOnly} label="Prezzo Privato" name="prezzo_privato" value={data.prezzo_privato} onChange={handleInputChange} />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField disabled={readOnly} label="Prezzo Professionista" name="prezzo_professionista" value={data.prezzo_professionista} onChange={handleInputChange} />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Categorie</InputLabel>
          <Select label="Categoria" name="categorie" multiple value={data.categorie} onChange={handleInputChange}>
            {listaCategorieAudio?.map((categoria, i) => (
              <MenuItem key={i} value={categoria.id}>
                {categoria.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          {!!selectedFile && <TextField InputProps={{ readOnly: true }} value={selectedFile.name} label="Nome File" />}
          <Button component="label" variant="contained" sx={{ my: 2 }} startIcon={<CloudUploadOutlined />}>
            Scegli un file audio
            <input type="file" onChange={onFileChange} style={{ display: "none" }} accept="audio/*" />
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
      </Grid>
      {
        data.url ?
          <Grid item xs={4} key={data.url + "?" + data.updated_at}>
            <FormControl fullWidth>
              <Typography variant="subtitle1">Ultimo Audio File Salvato</Typography>
              <audio controls style={{ maxWidth: "500px", maxHeight: "400px" }}>
                <source src={data.url + "?" + data.updated_at} type="audio/mp3" />
              </audio>
            </FormControl>
          </Grid>
          : <></>
      }



    </>
  );
};

const AudioDetails = ({ setIsEditorOpen, selectedAudio = false }) => {
  const dispatch = useDispatch();
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);
  const { listaCategorieAudio } = useSelector((state) => state.dashboardAdmin);
  const [audioData, setAudioData] = useState({ preset_durata_professionista: null, preset_durata_privato: null, tipo_durata_professionista: '1', durata_professionista: null, tipo_durata_privato: '1', durata_privato: null, nome: "", descrizione: "", categorie: [], codice_media: "", prezzo_privato: 0, prezzo_professionista: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [errorAudio, setErrorAudio] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (selectedAudio?.id) loadAudio(selectedAudio.id);

    dispatch(getListaCategorieAudio());
  }, []);

  const loadAudio = async (id) => {
    try {
      setIsLoading(true);
      let data;
      let categorie = [];
      const response = await getAudio(id, tokenAdmin);
      data = response.data;
      categorie = data.categorie;
      data.categorie = categorie.map((categoria) => categoria.id);
      setAudioData(data);
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), severity: "error" }));
      onClose()
    } finally {
      setIsLoading(false);
    }
  };

  const handleAudioSubmit = async (pubblica) => {
    let response;
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append("nome", audioData.nome);
      data.append("descrizione", audioData.descrizione ?? '');
      data.append("categorie", JSON.stringify(audioData.categorie));
      data.append("file", selectedFile);
      data.append("codice_media", audioData.codice_media);
      data.append("prezzo_privato", audioData.prezzo_privato);
      data.append("prezzo_professionista", audioData.prezzo_professionista);

      data.append("tipo_durata_privato", audioData.tipo_durata_privato);
      if (audioData.tipo_durata_privato == '2') {
        data.append("durata_privato", audioData.durata_privato);
      }else if(audioData.tipo_durata_privato == '3' && audioData.tipo_durata_privato != null){
        data.append('preset_durata_privato', audioData.preset_durata_privato);
      }

      data.append("tipo_durata_professionista", audioData.tipo_durata_professionista);
      if (audioData.tipo_durata_professionista == '2') {
        data.append("durata_professionista", audioData.durata_professionista);
      }else if(audioData.tipo_durata_privato == '3' && audioData.preset_durata_professionista != null){
        data.append('preset_durata_professionista', audioData.preset_durata_professionista);
      }





      if (selectedAudio || isCreated) {
        response = await modifyAudio(data, tokenAdmin, audioData.id);
      } else {
        response = await creaAudio(data, tokenAdmin);
      }

    } catch (e) {
      setErrorAudio(getAPIErrorMessage(e));
      return;
    } finally {
      setIsLoading(false);
    }
    dispatch(setSnackbarData({ message: "Audio salvato con successo", severity: "success" }));
    await loadAudio(response.data.id);
    setIsCreated(true);
  };



  const onClose = () => {
    dispatch(getListaAudio());
    setErrorAudio("");
    setIsEditorOpen(false);
  };

  return (
    <>
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box sx={{ bgcolor: "#fff", p: 2 }} component={Paper}>
        <Grid container columnSpacing={2} rowSpacing={4} alignItems="center">
          <Grid item xs={selectedAudio || isCreated ? 6 : 12}>
            <Button startIcon={<ArrowBack />} onClick={onClose}>
              Indietro
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ py: 2 }} display="flex" justifyContent="center">
            <Typography variant="title2">{audioData?.id ? "Modifica Audio" : "Aggiungi Audio"}</Typography>
          </Grid>
          <Grid item container columnSpacing={2} rowSpacing={4} xs={12}>
            <GridData selectedFile={selectedFile} setSelectedFile={setSelectedFile} data={audioData} setData={setAudioData} liste={{ listaCategorieAudio }} />
          </Grid>


          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", mb: 2 }}>
              <Box sx={{ flex: 1 }}></Box>
              <Button variant="contained" onClick={() => handleAudioSubmit(false)} color="primary" sx={{ ml: 2 }} startIcon={<Save />}>
                {selectedAudio || isCreated ? "Salva Audio" : "Crea Audio"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={!!errorAudio} onClose={() => setErrorAudio("")} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {errorAudio}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setErrorAudio("")}>
            OK
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
};

export default AudioDetails;
